"use client";

import { useRouter } from "next/navigation";``
import { createContext, useContext } from "react";
import { useDispatch } from "react-redux";
import { UserAuthResetvalue } from "../_utils/redux/features/UserAuth/UserAuthSlice";
import Cookies from "js-cookie";

const LogoutContext = createContext();

export const LogoutContextProvider = ({ children }) => {
  const dispatch = useDispatch();
  const router = useRouter();

  const handleLogout = () => {
    dispatch(UserAuthResetvalue());
    Cookies.remove("token");
    Cookies.remove("user_id");
    router.push("/auth/login");
  };

  return (
    <LogoutContext.Provider value={{ handleLogout }}>
      {children}
    </LogoutContext.Provider>
  );
};


export const useLogoutContext = () => {
  return useContext(LogoutContext);
};
