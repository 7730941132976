import { createSlice } from "@reduxjs/toolkit";
import {
  UserAuthLogin,
  userAuthLoginOTP,
  userAuthResendOTP,
  UserAuthDetails,
  userAuthResetPassword,
  userAuthUpdatePassword
} from "./UserAuthAction";

const getInitialStatus = () => ({
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorMsg: null,
  errorResponse: null,
  successMsg: null,
});

const initialState = {
  loginValidate: getInitialStatus(),
  VerifyOTP: getInitialStatus(),
  ResendOTP: getInitialStatus(),
  ResetPassword: getInitialStatus(),
  UpdatePassword: getInitialStatus(),
  GetUserAuthDetails: getInitialStatus(),
  data: {},
};

const userAuthSlice = createSlice({
  name: "UserAuth",
  initialState,
  reducers: {
    UserAuthResetvalue: (state) => {
      state.loginValidate = getInitialStatus();
      state.VerifyOTP = getInitialStatus();
      state.ResendOTP = getInitialStatus();
      state.ResetPassword = getInitialStatus();
      state.UpdatePassword = getInitialStatus();
      state.GetUserAuthDetails = getInitialStatus();
    },
    UserAuthResetvalueData: (state) => {
      state.loginValidate = getInitialStatus();
      state.VerifyOTP = getInitialStatus();
      state.ResendOTP = getInitialStatus();
      state.ResetPassword = getInitialStatus();
      state.UpdatePassword = getInitialStatus();
      state.GetUserAuthDetails = getInitialStatus();
    },
  },
  extraReducers: (builder) => {
    // login
    builder.addCase(UserAuthLogin.pending, (state) => {
      state.loginValidate = getInitialStatus();
      state.loginValidate.isLoading = true;
    });
    builder.addCase(UserAuthLogin.fulfilled, (state, action) => {
      state.loginValidate = getInitialStatus();
      state.loginValidate.isSuccess = true;
      const { valid, status, message, token, user_id } = action.payload;
      state.loginValidate.successMsg = message;
      state.data = action.payload;
      // console.log('action.payload;',action.payload);
      
    });
    builder.addCase(UserAuthLogin.rejected, (state, action) => {
      state.loginValidate = getInitialStatus();
      state.loginValidate.isError = true;
      state.loginValidate.errorMsg = action.payload?.response;
      state.loginValidate.errorResponse = action.payload;
    });
    // loginotp
    builder.addCase(userAuthLoginOTP.pending, (state) => {
      state.VerifyOTP = getInitialStatus();
      state.VerifyOTP.isLoading = true;
    });
    builder.addCase(userAuthLoginOTP.fulfilled, (state, action) => {
      state.VerifyOTP = getInitialStatus();
      state.VerifyOTP.isSuccess = true;
      state.data = action.payload;
    });
    builder.addCase(userAuthLoginOTP.rejected, (state, action) => {
      state.VerifyOTP = getInitialStatus();
      state.VerifyOTP.isError = true;
      state.VerifyOTP.errorMsg = action.payload?.response;
      state.VerifyOTP.errorResponse = action.payload;
    });
    builder.addCase(userAuthResendOTP.pending, (state) => {
      state.ResendOTP = getInitialStatus();
      state.ResendOTP.isLoading = true;
    });
    builder.addCase(userAuthResendOTP.fulfilled, (state, action) => {
      state.ResendOTP = getInitialStatus();
      state.ResendOTP.isSuccess = true;
      state.data = action.payload;
    });
    builder.addCase(userAuthResendOTP.rejected, (state, action) => {
      state.ResendOTP = getInitialStatus();
      state.ResendOTP.isError = true;
      state.ResendOTP.errorMsg = action.payload?.response;
      state.ResendOTP.errorResponse = action.payload;
    });
    // reset password
    builder.addCase(userAuthResetPassword.pending, (state) => {
      state.ResetPassword = getInitialStatus();
      state.ResetPassword.isLoading = true;
    });
    builder.addCase(userAuthResetPassword.fulfilled, (state, action) => {
      state.ResetPassword = getInitialStatus();
      state.ResetPassword.isSuccess = true;
      state.data = action.payload;
    });
    builder.addCase(userAuthResetPassword.rejected, (state, action) => {
      state.ResetPassword = getInitialStatus();
      state.ResetPassword.isError = true;
      state.ResetPassword.errorMsg = action.payload?.message;
      state.ResetPassword.errorResponse = {
        message: action.payload?.message,
        status: action.payload?.status,
      };
    });
      // update password
      builder.addCase(userAuthUpdatePassword.pending, (state) => {
      state.UpdatePassword = getInitialStatus();
      state.UpdatePassword.isLoading = true;
    });
    builder.addCase(userAuthUpdatePassword.fulfilled, (state, action) => {
      state.UpdatePassword = getInitialStatus();
      state.UpdatePassword.isSuccess = true;
      state.data = action.payload;
    });
    builder.addCase(userAuthUpdatePassword.rejected, (state, action) => {
      state.UpdatePassword = getInitialStatus();
      state.UpdatePassword.isError = true;
      state.UpdatePassword.errorMsg = action.payload?.message;
      state.UpdatePassword.errorResponse = {
        message: action.payload?.message,
        status: action.payload?.status,
      };
    });

    // user details
    builder.addCase(UserAuthDetails.pending, (state) => {
      state.GetUserAuthDetails = getInitialStatus();
      state.GetUserAuthDetails.isLoading = true;
    });
    builder.addCase(UserAuthDetails.fulfilled, (state, action) => {
      state.GetUserAuthDetails = getInitialStatus();
      state.GetUserAuthDetails.isSuccess = true;
      state.data = action.payload;
    });
    builder.addCase(UserAuthDetails.rejected, (state, action) => {
      state.GetUserAuthDetails = getInitialStatus();
      state.GetUserAuthDetails.isError = true;
      state.GetUserAuthDetails.errorMsg = action.payload?.response;
      state.GetUserAuthDetails.errorResponse = action.payload;
    });
  },
});

export const { UserAuthResetvalue, UserAuthResetvalueData } =
  userAuthSlice.actions;
export default userAuthSlice.reducer;
