

import { format, subDays, startOfDay, endOfDay } from 'date-fns';


export const roundToTwoDecimals = (num) => {
  if (num === 'Infinity') {
    return "0";
  }
    const parsedNum = parseFloat(num);
    return !isNaN(parsedNum) ? parsedNum.toFixed(2) : "0";
  };

 export const formatDate = (dateString) => {
  if (!dateString) return "N/A";

  const year = dateString.slice(0, 4);
  const month = dateString.slice(4, 6);
  const day = dateString.slice(6, 8);

  return ` ${day}-${month}-${year}`;
 
};




export const formatNumber = (value, type) => {
  switch (type) {
    case 'currency':
      return `$${parseFloat(value).toFixed(2)}`;
    case 'percent':
      return `${parseFloat(value).toFixed(2)}%`;
    case 'integer':
      return parseInt(value, 10).toString(); 
    default:
      return value;
  }
};


// export const formatLabel = (label) => {
//   return label
//     .replace(/_/g, ' ') 
//     .toLowerCase()   
//     .replace(/(?:^|\s)\S/g, (a) => a.toUpperCase()); 
// };

export const formatLabel = (label, fullUppercaseLabels = new Set()) => {
  if (fullUppercaseLabels.has(label.toLowerCase())) {
    return label.toUpperCase();
  }

  return label
    .replace(/_/g, ' ')
    .toLowerCase()
    .replace(/(?:^|\s)\S/g, (a) => a.toUpperCase());
};





export function formatDateAndTime(dateString) {
  return format(new Date(dateString), ' dd-MM-yyyy HH:mm a');
}


export const isValidUUID = (id) => {
  const uuidPattern = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
  return uuidPattern.test(id);
};

const formatMetric = (value, type) => {
  switch(type) {
    case 'numeric':
      return Number(value).toLocaleString();
    case 'currency':
      return `$${Number(value).toFixed(2)}`;
    case 'percent':
      return `${Number(value).toFixed(2)}%`;
    default:
      return value;
  }
};


export function validateEmail(email) {
  const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
  return emailRegex.test(email);
}


// array flaten

export const filterDataWithSearchChannel = (data, searchQuery) => {
  if (!searchQuery.trim()) return data; 

  return data.filter((item) => {
    if (!item || typeof item !== 'object') return false;

    const flattenedItem = Object.keys(item).reduce((acc, key) => {
      const value = item[key];

      if (Array.isArray(value)) {
        acc[key] = value.join('\n'); 
      } else {
        acc[key] = value;
      }

      return acc;
    }, {});


    return Object.values(flattenedItem).some((value) => {
      const strValue = String(value).toLowerCase();
      const query = searchQuery.toLowerCase();
      console.log(`Checking value: ${strValue} against query: ${query}`); 
      return strValue.includes(query);
    });
  });
};




export const formatDateWithHyphens = (dateString) => {
  if (!dateString || dateString.length !== 8) return dateString; 

  const year = dateString.substring(0, 4);
  const month = dateString.substring(4, 6);
  const day = dateString.substring(6, 8);

  return `${year}-${month}-${day}`;
};




export const formatDateForRedux = (date) => {
  if (!date || isNaN(date.getTime())) return "";
  const day = `0${date.getDate()}`.slice(-2);
  const month = `0${date.getMonth() + 1}`.slice(-2);
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
};

export const calculateDates = (option) => {
  // console.log('Received option:', option);

  const now = new Date();
  now.setHours(0, 0, 0, 0);
  const yesterday = new Date(now.getTime() - 24 * 60 * 60 * 1000);
  const last7DaysStart = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);

  let from_date, to_date;

  switch (option) {
    case "Today":
      from_date = to_date = formatDateForRedux(now);
      break;
    case "Yesterday":
      from_date = to_date = formatDateForRedux(yesterday);
      break;
    case "Last 7 days":
      from_date = formatDateForRedux(last7DaysStart);
      to_date = formatDateForRedux(yesterday);
      break;
    case "This month":
      from_date = formatDateForRedux(new Date(now.getFullYear(), now.getMonth(), 1));
      to_date = formatDateForRedux(now);
      break;
    case "Last month":
      from_date = formatDateForRedux(new Date(now.getFullYear(), now.getMonth() - 1, 1));
      to_date = formatDateForRedux(new Date(now.getFullYear(), now.getMonth(), 0));
      break;
    default:
      from_date = to_date = "";
  }

  // console.log('Calculated dates:', { from_date, to_date, selectedOption: option });
  return { from_date, to_date, selectedOption: option };
};




export const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  console.log('color',color);
  
  return color;
};

