import { axiosInstance } from "@/app/_utils/others/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

export const GetALlChannelIdenfierAction = createAsyncThunk(
  "v1/notification-data",
  async (req, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "v1/notification-data",
        req,
        {
          headers: {
            Authorization: Cookies.get("token")
              ? `${JSON.parse(Cookies.get("token"))}`
              : "",
          },
        }
      );
      if (response.status >= 200 && response.status < 300) {
        const data = response?.data;
        return data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data || error.message);
    }
  }
);

export const GetCustomUserFilterDataAction = createAsyncThunk(
  "v1/get-filter-data",
  async (req, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "v1/get-filter-data",
        req,
        {
          headers: {
            Authorization: Cookies.get("token")
              ? `${JSON.parse(Cookies.get("token"))}`
              : "",
          },
        }
      );
      if (response.status >= 200 && response.status < 300) {
        const data = response?.data;
        return data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data || error.message);
    }
  }
);




export const GetLogFilterAction = createAsyncThunk(
  "v1/filter-report-data",
  async (req, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "v1/filter-report-data",
        req,
        {
          headers: {
            Authorization: Cookies.get("token")
              ? `${JSON.parse(Cookies.get("token"))}`
              : "",
          },
        }
      );
      if (response.status >= 200 && response.status < 300) {
        const data = response?.data;
        return data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data || error.message);
    }
  }
);


export const GetExportActivityLogAction = createAsyncThunk(
  "v1/export-csv-excel",
  async (req, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "v1/export-csv-excel",
        req,
        {
          headers: {
            Authorization: Cookies.get("token")
              ? `${JSON.parse(Cookies.get("token"))}`
              : "",
          },
        }
      );
      if (response.status >= 200 && response.status < 300) {
        const data = response?.data;
        return data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data || error.message);
    }
  }
);




export const GetNotificationAction = createAsyncThunk(
  "v1/notify-interval",
  async (req, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "v1/notify-interval",
        req,
        {
          headers: {
            Authorization: Cookies.get("token")
              ? `${JSON.parse(Cookies.get("token"))}`
              : "",
          },
        }
      );
      if (response.status >= 200 && response.status < 300) {
        const data = response?.data;
        return data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data || error.message);
    }
  }
);

export const NotificationReadUnreadAction = createAsyncThunk(
  "v1/read-unread",
  async (req, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "v1/read-unread",
        req,
        {
          headers: {
            Authorization: Cookies.get("token")
              ? `${JSON.parse(Cookies.get("token"))}`
              : "",
          },
        }
      );
      if (response.status >= 200 && response.status < 300) {
        const data = response?.data;
        return data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data || error.message);
    }
  }
);