import { createSlice } from "@reduxjs/toolkit";
import {
  GetALlChannelIdenfierAction,
  GetNotificationAction,
  NotificationReadUnreadAction,
  GetLogFilterAction,
  GetCustomUserFilterDataAction,
  GetExportActivityLogAction
} from "./ActivityLogAction";
import { format } from "date-fns";

const getInitialStatus = () => ({
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorMsg: null,
  errorResponse: null,
  successMsg: null,
});

const initialState = {
  getAllChannel: getInitialStatus(),
  getNotification: getInitialStatus(),
  ReadUnreadNotify: getInitialStatus(),
  GetFiltersfromRes:getInitialStatus(),
  GetCustomFilterData: getInitialStatus(),
  ExportData: getInitialStatus(), 
  exportExcelData: null,
  channelData: [],
  Filterdata:[],
  UnReadNotification: 0,
  NotificationData: [],
  OldNotificationData: [],
  totalCount: 0,
};

const ActivityLogSlice = createSlice({
  name: "ActivityLog",
  initialState,
  reducers: {
    reset: (state) => {
      state.getAllChannel = getInitialStatus();
      state.getNotification = getInitialStatus();
      state.NotificationReadUnread = getInitialStatus();
      state.ExportData = getInitialStatus();
    },
    updateOldNotificationData(state) {
      state.OldNotificationData = [...state.NotificationData];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GetALlChannelIdenfierAction.pending, (state) => {
      state.getAllChannel = getInitialStatus();
      state.getAllChannel.isLoading = true;
    });
    builder.addCase(GetALlChannelIdenfierAction.rejected, (state, action) => {
      state.getAllChannel.isLoading = false;
      state.getAllChannel.isSuccess = false;
      state.getAllChannel.isError = true;
      state.getAllChannel.errorMsg = action.error.message;
    });
    builder.addCase(GetALlChannelIdenfierAction.fulfilled, (state, action) => {
      state.getAllChannel = getInitialStatus();
      state.getAllChannel.isSuccess = true;
      const {
        totalCount = 0,
        result = [],
        message = "",
      } = action.payload || {};
      state.getAllChannel.successMsg = message;
      state.channelData = result;
      state.totalCount = totalCount;
    });
    builder.addCase(GetCustomUserFilterDataAction.pending, (state) => {
      state.GetCustomFilterData = getInitialStatus();
      state.GetCustomFilterData.isLoading = true;
    });
    builder.addCase(GetCustomUserFilterDataAction.rejected, (state, action) => {
      state.GetCustomFilterData.isLoading = false;
      state.GetCustomFilterData.isError = true;
      state.GetCustomFilterData.errorMsg = action.error.message;
    });
    builder.addCase(GetCustomUserFilterDataAction.fulfilled, (state, action) => {
      state.GetCustomFilterData.isLoading = false;
      state.GetCustomFilterData.isSuccess = true;
      const { result = [], message = "" } = action.payload || {};
      state.Filterdata = result[0]?.filter_data || {
        type: true,
        user: true,
        action: true,
        message: true,
        action_time: true,
        to_date: format(new Date(), "yyyy-MM-dd"),
        from_date: format(new Date(), "yyyy-MM-dd"),
      };
      state.getAllChannel.successMsg = message;
    });
    // export action

    builder.addCase(GetExportActivityLogAction.pending, (state) => {
      state.ExportData = getInitialStatus();
      state.ExportData.isLoading = true;
    });
    builder.addCase(GetExportActivityLogAction.rejected, (state, action) => {
      state.ExportData.isLoading = false;
      state.ExportData.isError = true;
      state.ExportData.errorResponse = action.payload;
    });
    builder.addCase(GetExportActivityLogAction.fulfilled, (state, action) => {
      state.ExportData = getInitialStatus();
      state.ExportData.isSuccess = true;
      state.exportExcelData = action.payload; 
    });

    // getNotification Filter
    builder.addCase(GetLogFilterAction.pending, (state) => {
      state.GetFiltersfromRes = getInitialStatus();
      state.GetFiltersfromRes.isLoading = true;
    });
    builder.addCase(GetLogFilterAction.rejected, (state, action) => {
      state.GetFiltersfromRes.isLoading = false;
      state.GetFiltersfromRes.isSuccess = false;
      state.GetFiltersfromRes.isError = true;
      state.GetFiltersfromRes.errorMsg = action.error.message;
    });
    builder.addCase(GetLogFilterAction.fulfilled, (state, action) => {
      state.GetFiltersfromRes = getInitialStatus();
      state.GetFiltersfromRes.isSuccess = true;
      const {
        message = "",
        totalCount = 0,
      } = action.payload || {};
      state.GetFiltersfromRes.successMsg = message;
    });

    // v1/notify-interval
    builder.addCase(GetNotificationAction.pending, (state) => {
      state.getNotification = getInitialStatus();
      state.getNotification.isLoading = true;
    });
    builder.addCase(GetNotificationAction.rejected, (state, action) => {
      state.getNotification = getInitialStatus();
      state.getNotification.isError = true;
      state.getNotification.errorResponse = action.payload;
    });
    builder.addCase(GetNotificationAction.fulfilled, (state, action) => {
      state.getNotification = getInitialStatus();
      state.getNotification.isSuccess = true;
      const {
        data = [],
        message = "",
        unread_count = "0",
      } = action.payload || {};
      state.getNotification.successMsg = message;
      state.NotificationData = data;
      state.UnReadNotification = unread_count;
    });

    // ReadUnreadNotify
    builder.addCase(NotificationReadUnreadAction.pending, (state) => {
      state.ReadUnreadNotify = getInitialStatus();
      state.ReadUnreadNotify.isLoading = true;
    });
    builder.addCase(NotificationReadUnreadAction.rejected, (state, action) => {
      state.ReadUnreadNotify = getInitialStatus();
      state.ReadUnreadNotify.isError = true;
      state.ReadUnreadNotify.errorResponse = action.payload;
    });
    builder.addCase(NotificationReadUnreadAction.fulfilled, (state, action) => {
      state.ReadUnreadNotify = getInitialStatus();
      state.ReadUnreadNotify.isSuccess = true;
      const { message = "" } = action.payload || {};
      state.ReadUnreadNotify.successMsg = message;
    });
  },
});

export const { updateOldNotificationData } = ActivityLogSlice.actions;
export default ActivityLogSlice.reducer;
