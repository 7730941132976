import { axiosInstance } from "@/app/_utils/others/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

export const UserAuthLogin = createAsyncThunk(
  "/v1/auth/login",
  async (req, thunkAPI) => {
    try {
      const response = await axiosInstance.post("/v1/auth/login", req.formData);
      if (response.status >= 200 && response.status < 300) {
        const data = response?.data;
        if (data.user_id) {
          Cookies.set("user_id", data.user_id);
          data.message = data.message || "Successfully sent OTP to your mail";
        }
        return data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const userAuthLoginOTP = createAsyncThunk(
  "v1/auth/verify-otp",
  async (req, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "/v1/auth/verify-otp",
        req.formData
      );
      if (response.status >= 200 && response.status < 300) {
        const data = response?.data;
        console.log("data", data);
        if (data?.token) {
          Cookies.set("token", JSON.stringify(data?.token));
        }
        data.message = data.message || "Successfully logged in";
        return data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "An unexpected error occurred"
      );
    }
  }
);

export const userAuthResendOTP = createAsyncThunk(
  "v1/auth/resend-otp",
  async (req, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "/v1/auth/resend-otp",
        req.formData
      );
      if (response.status >= 200 && response.status < 300) {
        const data = response?.data;
        // console.log(data);
        if (data?.token) {
          Cookies.set("token", JSON.stringify(data?.token));
        }
        data.message = data.message || "Successfully logged in";
        return data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const userAuthResetPassword = createAsyncThunk(
  "v1/auth/reset-password",
  async (formData, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "v1/auth/reset-password",
        formData
      );
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({
        message: error.response?.data?.message || "An error occurred",
        status: error.response?.status,
      });
    }
  }
);

export const userAuthUpdatePassword = createAsyncThunk(
  "v1/auth/update-password",
  async (formData, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "v1/auth/update-password",
        formData
      );
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({
        message: error.response?.data?.message || "An error occurred",
        status: error.response?.status,
      });
    }
  }
);

export const UserAuthDetails = createAsyncThunk(
  "v1/auth/user-details",
  async (req, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "v1/auth/user-details",
        req.formData,
        {
          headers: {
            Authorization: Cookies.get("token")
              ? `${JSON.parse(Cookies.get("token"))}`
              : "",
          },
        }
      );
      if (response.status >= 200 && response.status < 300) {
        const data = response?.data;
        // console.log('res',data);
        return data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response);
    }
  }
);
